<template>
  <div>
    <b-button-toolbar key-nav class="mb-3">
      <b-button size="sm" variant="primary" :to="{ name: 'ToyRegister' }">
        <span class="fas fa-plus mr-1" /> Cadastrar Brinquedo</b-button
      >
    </b-button-toolbar>

    <b-alert v-if="error" show variant="danger">
      <span class="fas fa-exclamation-triangle" /> {{ error }}
    </b-alert>

    <b-table
      ref="toysList"
      :fields="fields"
      :items="provider"
      striped
      hover
      show-empty
      :busy="isBusy"
    >
      <div slot="empty" class="text-center">Nenhum brinquedo cadastrado</div>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="getColumnStyle(field.key)"
        />
      </template>
      <template #cell(image)="{ value: image, item }">
        <img :src="image" :alt="item.name" class="img-fluid" />
      </template>
      <template #cell(store)="{ value: store }">
        {{ store.name }}
      </template>
      <template #cell(status)="{ value: status }">
        <Status :value="status" />
      </template>
      <template #cell(actions)="{ item }">
        <BaseListActions
          :item="item"
          @edit="onEdit"
          @remove="onRemove"
          @updateStatus="onUpdateStatus"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import services from '@/services'
import Status from '@/components/Status'
import BaseListActions from '@/components/BaseListActions'
import withStatusAction from '@/mixins/withStatusAction'
import { parseHTTPError } from '@/helpers/errors'

export default {
  name: 'ToysList',
  components: {
    Status,
    BaseListActions
  },
  mixins: [withStatusAction(services.toys.updateToy)],
  data() {
    return {
      isBusy: false,
      error: null
    }
  },
  computed: {
    fields: () => [
      {
        key: 'image',
        label: 'Imagem'
      },
      {
        key: 'code',
        label: 'Código'
      },
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'store',
        label: 'Loja'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  },
  methods: {
    async provider() {
      this.isBusy = true
      try {
        return await services.toys.fetchAllToys()
      } catch (error) {
        this.error = parseHTTPError(error)
        return []
      } finally {
        this.isBusy = false
      }
    },
    onEdit(toy) {
      const { id } = toy
      this.$router.push({ name: 'ToyRegister', params: { id } })
    },
    async onRemove(toy) {
      const message = `Deseja realmente remover o brinquedo ${toy.name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        await services.toys.removeToy(toy.id)
        this.$refs.toysList.refresh()
        this.$root.$toast.success('Brinquedo removido com sucesso')
      }
    },
    async onUpdateStatus(toy) {
      await this.toggleStatus(toy)
      this.$refs.toysList.refresh()
    },
    getColumnStyle(field) {
      switch (field) {
        case 'actions':
          return { width: '1%' }
        case 'image':
          return { width: '15%' }
        default:
          return ''
      }
    }
  }
}
</script>
